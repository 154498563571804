import React from 'react';
import { graphql } from 'gatsby';
import { Page, Section } from 'src/components';
import { Markdown } from 'src/kit';

export const query = graphql`
  query LegalAgreementQuery($slug: String) {
    graphCmsAgreement(slug: { eq: $slug }) {
      id
      slug
      title
      body
    }
  }
`;

// let legalagreemnts render markdown tables
const LegalAgreement = ({ data }) => {
  const post = data?.graphCmsAgreement;
  return (
    <Page title={post?.title} path={`legal/${post?.slug}`}>
      <Section nav narrow color="peach" gradient>
        <h1>{post?.title}</h1>
        <div className="legal-template">
          <Markdown>{post?.body}</Markdown>
        </div>
      </Section>
    </Page>
  );
};

export default LegalAgreement;
